<template>
  <div class="chips chips-value">
    <div class="chips__label">
      {{ label }}
    </div>
    <div class="chips__arguments">

    </div>
    <!--<a class="chips__remove"></a>-->
  </div>
</template>

<script>
export default {
  name: 'ChipsValue',

  props: {
    value: Object
  },

  computed: {
    label () {
      if (this.model.value_type === 'string') {
        return 'Text: ' + this.model.value
      }

      return 'Number: ' + this.model.value
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  mounted () {
    console.log('ChipsValue', this.model)
  }
}
</script>

<style scoped></style>
